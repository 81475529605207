@import 'variable';
@import 'mixin';

/* ======================================================================
 p_404
====================================================================== */
.p_404 {
  .l_content {
    padding: 60px 0 80px;
    @include sp {
      padding: 60px 4.6875% 40px;
    }
    .l_main {
      ._second {
        margin: 30px 0 0;
        @include sp {
          margin: 30px 0 0;
        }
      }

      .box {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        .txtwrap {
          width: 650px;
        }
        .imgwrap {
          width: 260px;
          @include sp {
            width: 146px;
            margin: 30px auto 0;
          }
          img {
            max-width: 100%;
          }
        }
        .list02 {
          margin: 20px 0 0;
          >li {
            position: relative;
            padding: 0 0 0 20px;
            line-height: 1.7;
            &:not(:first-child) {
              margin: 0;
            }
            &:before {
              content: '';
              width: 8px;
              height: 8px;
              border: 1px solid #1163B3;
              border-radius: 50%;
              position: absolute;
              top: 8px;
              left: 2px;
            }
          }
        }

        h3 {
          margin: 30px 0 10px;
          font-size: 2.4rem;
          font-family: $min;
          position: relative;
          padding: 0 0 0 18px;
          font-weight: 600;
          line-height: 1.5;

          @include sp {
            font-size: 1.8rem;
          }
          &.ttl {
            margin: 20px 0 10px;
            @include sp {
              margin: 30px 0 10px;
            }
          }
          &:before {
            width: 3px;
            height: 100%;
            background: #0B5AA0;
            position: absolute;
            content:'';
            top: 0;
            left: 0;
            @include sp {
              top: 3px;
              width: 3px;
              height: 21px;
            }
          }
          .point {
            padding: 0 0 0 82px;
            &:before {
              left: -5px;
            }
            &:after {
              display: none;
            }
          }
        }

      }

      .btnwrap {
        width: 100%;
        margin: 60px 0 0;
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        justify-content: center;
        @include sp {
          margin: 40px 0 0;
        }
      }

    }
  }
}
